<template>
  <div class="commonFilter">
    <div class="gotoExwarehouse layout-breadcrumb">
      <Breadcrumb style="margin-top:5px">
        <BreadcrumbItem>{{ $t('key1006042') }}</BreadcrumbItem>
        <BreadcrumbItem>{{ $t('key1006043') }}</BreadcrumbItem>
      </Breadcrumb>
      <Button type="primary"
        @click="gotoExwarehouse"
        v-if="getPermission('packageInfo_getPackageByCode')"
        icon="md-swap">{{ $t('key1006044') }}
      </Button>
    </div>
    <Row>
      <Col :span="11">
        <Card :bordered="false" dis-hover>
          <template #title><p>{{ $t('key1005803') }}</p></template>
          <template #extra>
            <p>
            <span class="mr10">
              {{ $t('key1003194') }}
              <Icon v-if="formInline.enableWeight === 'Y'"
                type="md-checkmark" class="greenColor"></Icon>
              <Icon v-else type="close-round" class="redColor"></Icon>
            </span>
              <span class="mr10">
              {{ $t('key1006045') }}
              <Icon v-if="formInline.enableVolume === 'Y'" type="md-checkmark" class="greenColor"></Icon>
              <Icon v-else type="close-round" class="redColor"></Icon>
            </span>
              <span class="mr10">{{ $t('key1006046') }}<span v-text="
                  formInline.weightUnit === 'g'
                    ? $t('key1003211') + '（g）'
                    : $t('key1003212') + '(kg)'
                "></span><Tooltip max-width="200" :content="$t('key1003191')">
                <Icon type="md-help-circle"></Icon> </Tooltip></span>
              <span class="mr10">{{ $t('key1006047') }}<Icon v-if="formInline.autoDelivery === 'Y'"
                type="md-checkmark"
                class="greenColor"></Icon><Icon v-else
                type="close-round"
                class="redColor"></Icon></span>
              <Icon type="ios-settings" class="settingCursor" @click="model4 = true"></Icon>
            </p>
          </template>

          <div>
            <div class="flexBox packageCode">
              {{ packageModel.code }}
            </div>
            <div class="flexBox packageNationFlag">
              <span :class="[
                  'bigNationalFlag bignationalFlag' +
                    packageModel.webstoreItemSite,
                ]"></span>
            </div>
            <div class="flexBox shippingCode">
              <em>{{ packageModel.nation }}</em>
              {{ packageModel.postcode }}
            </div>
            <div class="packageSpecialItem normalTop">
              <span>{{ $t('key1003586') }}</span>
              <span class="">{{ packageModel.shippingMethod }}</span>
            </div>
            <div class="packageItem normalTop">
              <span>{{ $t('key1001132') }}</span>
              <span class="redColor">{{ packageModel.waybillNo }}</span>
            </div>
            <div class="packageItem normalTop">
              <span>{{ $t('key1006048') }}</span>
              <span class="greenColor">{{ packageModel.weight }}g</span>
            </div>
          </div>
        </Card>
        <div class="packageSetting">
          <div class="packageSettingBody">
            <Form :model="formItem" :label-width="70" @submit.native.prevent>
              <Row class="normalTop">
                <Col>
                  <FormItem :label="$t('key1003188')" prop="scan">
                    <Input v-model.trim="formItem.scan"
                      style="width:200px"
                      ref="input1"
                      clearable
                      :placeholder="$t('key1006038')"
                      @on-keyup.13="goNextInput(1)"></Input>
                    <Checkbox @on-change="saveLocalData" class="inline-block ml10" v-model="isSliceIdentificationNumber">{{ $t('key1006049') }}</Checkbox>
                    {{ $t('key1003314') }}
                    <InputNumber v-model="sliceStartLength" @on-change="saveLocalData" :disabled="!isSliceIdentificationNumber" :precision=0 style="height: 24px;" :min=0 :max=999999 class="w-40" size="small"/>
                    {{ $t('key1003315') }}
                    <InputNumber :disabled="!isSliceIdentificationNumber" @on-change="saveLocalData" :precision=0 style="height: 24px;" size="small" :min=0 :max=999999 class="w-40" v-model="sliceEndLength"/>
                    <Tooltip :content="$t('key1006039')"><Icon type="md-help-circle"/></Tooltip>
                  </FormItem>
                </Col>
              </Row>
              <Row v-if="localFormInline.enableWeight === 'Y'">
                <Col :span="10">
                  <FormItem :label="$t('key1003190')" prop="weigh">
                    <Input v-model.trim="formItem.weight"
                      ref="input2"
                      clearable
                      @on-keyup.13="goNextInput(2)"
                      @on-blur="toFixedTwo">
                      <template #append><span>{{
                          packageSettingModel.weight
                        }}</span></template>
                    </Input>
                  </FormItem>
                </Col>
              </Row>
              <Row v-if="localFormInline.enableVolume === 'Y'">
                <FormItem :label="$t('key1006040')">
                  <Col :span="5">
                    <Input v-model.trim="formItem.length" clearable ref="input3" :placeholder="$t('key1000748')" @on-keyup.13="goNextInput(3)">
                      <template #append><span>cm</span></template>
                    </Input>
                  </Col>
                  <Col :span="5" style="margin-left:5px">
                    <Input v-model.trim="formItem.width" clearable ref="input4" :placeholder="$t('key1000749')" @on-keyup.13="goNextInput(4)">
                      <template #append><span>cm</span></template>
                    </Input>
                  </Col>
                  <Col :span="5" style="margin-left:5px">
                    <Input v-model.trim="formItem.height" clearable ref="input5" :placeholder="$t('key1000750')" @on-keyup.13="goNextInput(5)">
                      <template #append><span>cm</span></template>
                    </Input>
                  </Col>
                </FormItem>
              </Row>
            </Form>
          </div>
        </div>
      </Col>
      <Col :span="12" offset="1" style="position: relative">
        <Tabs type="card" v-model="tabPane">
          <TabPane v-for="tab in tabPaneText" :key="tab.value" :name="tab.value" :label="tab.label">
            <div class="autoRight">
              <div class="scanPageItem" v-if="tab.value === '1'" v-for="(item, index) in scanData" :key="index">
                <div class="scanPageItemHeader">
                  <div class="scanPageItemHeaderContent flexBox" style="justify-content: space-between;">
                    <span>{{ $t('key1003998') }}
                      <span class="blueColor spicLeft">{{
                          shippingMethodPos[index]
                        }}</span></span>
                    <span> </span>
                  </div>
                </div>
                <div class="scanPageItemBody">
                  <Table highlight-row border :columns="scanColumn" :data="item" size="small"></Table>
                </div>
              </div>
              <!--正常的扫描标记发货-->
              <div class="scanPageItem"
                v-if="tab.value === '2' && !isSupplier"
                v-for="(item, index) in goodsData"
                :key="index">
                <div class="scanPageItemHeader">
                  <div class="scanPageItemHeaderContent flexBox" style="justify-content: space-between;">
                    <span>{{ $t('key1006050') }}
                      <span class="greenColor spicLeft">{{
                          shippingGoodsMethodPos[index]
                        }}</span></span>
                    <span>
                      <Button type="primary" size="small" v-if="
                          getPermission('packageInfo_createExcelForBagging')
                        " icon="ios-basket" @click="setPackageOver(item, index)">{{ $t('key1003197') }}</Button>
                    </span>
                  </div>
                </div>
                <div class="scanPageItemBody">
                  <Table highlight-row border :columns="goodsColumn" :data="item" size="small"></Table>
                </div>
              </div>
              <!--供应商的扫描标记发货-->
              <div class="scanPageItem" v-if="tab.value === '2' && isSupplier && supplierDeliveryData.length >0">
                <div class="scanPageItemHeader">
                  <div class="scanPageItemHeaderContent flexBox" style="justify-content: flex-end;">
                    <Button type="primary" size="small" icon="ios-basket" @click="supplierBaggingBtn">{{ $t('key1003197') }}</Button>
                  </div>
                </div>
                <div class="scanPageItemBody">
                  <Table highlight-row
                    border
                    :columns="goodsColumn"
                    :data="supplierDeliveryData"
                    size="small"></Table>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
        <div class="scanFooter" v-if="scanData.length > 0 && tabPane === '1'">
          <div class="scanFooterBox">
            <Button size="large"
              class="mr10"
              v-if="getPermission('packageInfo_scanBatchMarkPackageDelivery')"
              @click="batchMarkGoods(null)">{{ $t('key1006051') }}
            </Button>
            <Button class="ml10" size="large" @click="deleteAllGoods" type="error">{{ $t('key1006052') }}</Button>
          </div>
        </div>
      </Col>
    </Row>
    <Modal v-model="model4" :title="$t('key1003307')" width="700" @on-visible-change="model4Visible">
      <Form ref="formInline" :model="formInline" :rules="ruleInline" @submit.native.prevent>
        <p class="modelTit">{{ $t('key1003307') }}</p>
        <FormItem>
          <Row>
            <Col span="6">
              <Checkbox v-model="formInline.enableWeight" :true-value="'Y'" :false-value="'N'">{{ $t('key1006053') }}</Checkbox>
            </Col>
            <Col span="16">
              <Select v-model="formInline.weightUnit" size="small" style="width: 150px;" filterable>
                <Option v-for="(item, index) in weightTypeList" :key="index" :value="item.value"> {{ item.label }}
                </Option>
              </Select>
            </Col>
          </Row>
        </FormItem>
        <FormItem>
          <Checkbox v-model="formInline.enableVolume" :true-value="'Y'" :false-value="'N'">{{ $t('key1006054') }}</Checkbox>
        </FormItem>
        <FormItem>
          <Checkbox v-model="formInline.autoDelivery" :true-value="'Y'" :false-value="'N'">{{ $t('key1006047') }}</Checkbox>
        </FormItem>
        <p class="modelTit">
          {{ $t('key1006055') }} </p>
        <p>{{ $t('key1006056') }}</p>
        <FormItem>
          <Checkbox class="inline-block" v-model="formInline.singleWeightRemark" :true-value="'Y'" :false-value="'N'">{{ $t('key1000180') }}
          </Checkbox>
          {{ $t('key1006057') }}
          <RadioGroup v-model="disabledGroup">
            <Radio label="0">
              <Input style="width: 60px" clearable v-model.trim="formInline.singleTranscend"/>
              {{ $t('key1003211') }}
            </Radio>
            <Radio label="1">
              <Input style="width: 60px" clearable v-model.trim="formInline.singleTranscendPercent"/>
              %
            </Radio>
          </RadioGroup>
          {{ $t('key1006058') }}
        </FormItem>
        <FormItem>
          <Checkbox class="inline-block" v-model="formInline.multiWeightRemark" :true-value="'Y'" :false-value="'N'">{{ $t('key1000179') }}
          </Checkbox>
          {{ $t('key1006057') }}
          <RadioGroup v-model="disabled1Group">
            <Radio label="0">
              <Input style="width: 60px" clearable v-model.trim="formInline.multiTranscend"/>
              {{ $t('key1003211') }}
            </Radio>
            <Radio label="1">
              <Input style="width: 60px" clearable v-model.trim="formInline.multiTranscendPercent"/>
              %
            </Radio>
          </RadioGroup>
          {{ $t('key1006058') }}
        </FormItem>
        <p class="modelTit">
          {{ $t('key1006059') }} </p>
        <FormItem>
          <Checkbox class="inline-block"
            v-model="formInline.eachPiecePostageRemark"
            :true-value="'Y'"
            :false-value="'N'">{{ $t('key1006060') }}{{currencyState}}
          </Checkbox>
          <Input style="width: 60px" clearable v-model.trim="formInline.postageTranscend"/>
          {{ $t('key1006058') }}
        </FormItem>
      </Form>
      <template #footer>
        <div>
          <Button size="small" @click="model4 = false">{{ $t('key1000205') }}</Button>
          <Button size="small"
            type="primary"
            v-if="getPermission('packageSetting_saveDeliverySetting')"
            @click="saveDeliverySetting">{{ $t('key1000462') }}
          </Button>
        </div>
      </template>

    </Modal>
    <Modal v-model="model5" :title="$t('key1006041')" width="600">
      <div v-if="validErrorData">
        <Alert type="warning" show-icon>
          {{ validErrorData.error }}
        </Alert>
        <Table :columns="columns5" :data="validErrorData.packageDetails" height="300"></Table>
        <div class="text-right valid-bottom">
          <p>
            <span class="valid-tit">{{ $t('key1006061') }}</span>
            {{ validErrorData.productWeight }}
          </p>
          <p>
            <span class="valid-tit">{{ $t('key1006061') }}</span>
            {{ validErrorData.PackingMaterialsWeightSubtotal }}
          </p>
          <p>
            <span class="valid-tit">{{ $t('key1006062') }}</span>
            {{ validErrorData.productWeight + validErrorData.materialWeight }}
          </p>
        </div>
      </div>
      <template #footer>
        <div>
          <Button type="primary" @click="continueDeliverGoods">{{ $t('key1006063') }}</Button>
          <Button @click="model5 = false">{{ $t('key1006064') }}</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="overbagModel.status" @on-ok="overBag">
      <div class="ivu-modal-confirm">
        <div class="ivu-modal-confirm-head">
          <div class="ivu-modal-confirm-head-title">
            {{ $t('key1003197') }}
          </div>
        </div>
        <div class="ivu-modal-confirm-body ivu-modal-confirm-body-render">
          <div class="overbagContent">
            <div class="flexBox flexBoxJustContent overbagShippingName normalTop">
              {{ overbagModel.overbagShippingName }}
            </div>
            <div class="flexBox flexBoxJustContent overbagParams normalTop">
              <span>{{ overbagModel.packageCount }}</span>
              {{ $t('key1006065') }}
            </div>
            <div class="flexBox flexBoxJustContent overbagParams normalTop"
              v-if="storeagePackageSetting.indexOf($t('key1003194')) >= 0">
              <span>{{ overbagModel.packageWeight / 1000 }} KG</span>
              {{ $t('key1006066') }}
            </div>
            <div class="flexBox flexBoxJustContent normalTop">
              <Checkbox v-model="overbagModel.overbagConfirm">{{ $t('key1006067') }}</Checkbox>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <!--供应商扫描标记发货结袋的确认弹窗-->
    <Modal v-model="supplierdeliveryModal" @on-ok="supplierQueryBtn">
      <div class="ivu-modal-confirm">
        <div class="ivu-modal-confirm-head">
          <div class="ivu-modal-confirm-head-title">
            {{ $t('key1003197') }}
          </div>
        </div>
        <div class="ivu-modal-confirm-body ivu-modal-confirm-body-render">
          <div class="overbagContent">
            <div class="flexBox flexBoxJustContent overbagParams normalTop">
              <span>{{ supplierDeliveryData.length }}</span>
              {{ $t('key1006065') }}
            </div>
            <div class="flexBox flexBoxJustContent overbagParams normalTop"
              v-if="storeagePackageSetting.indexOf($t('key1003194')) >= 0">
              <span>{{ supplierPackageWeight / 1000 }} KG</span>
              {{ $t('key1006066') }}
            </div>
            <div class="flexBox flexBoxJustContent normalTop">
              <Checkbox v-model="supplierPrint">{{ $t('key1000919') }}</Checkbox>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <!--打印箱唛-->
    <Modal v-model="printTalg"
      class-name="printCaseMarkStyle"
      :title="$t('key1000919')"
      width="500"
      :transfer="false"
      :mask-closable="false">
      <div class="printCaseMarkBox" ref="container" :style="styles">
        <p class="print_item">{{ $t('key1000920') + $uDate.getDataToLocalTime(printInfo.createdTime, 'fulltime') }}</p>
        <font class="bar_code"
          style="font-family: IDAutomationC128S; margin-top: 5px;">{{ printInfo.skuBarcode }}
        </font>
        <p class="print_item" style="margin: 10px auto 5px">{{ printInfo.pickupOrderNumber }}</p>
        <p class="print_item">{{ $t('key1000185') + printInfo.packageQuantity }}</p>
      </div>
      <div ref="tagTemp" style="display: none;"></div>
    </Modal>
  </div>
</template>
<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';

export default {
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      isSupplier: true,
      isSliceIdentificationNumber: false,
      sliceStartLength: 0,
      sliceEndLength: 0,
      printTalg: false,
      // 标发货提示参数
      columns5: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000161'),
          minWidth: 120,
          render(h, params) {
            return this.tableImg(h, params, 'pictureUrl');
          }
        }, {
          title: 'SKU',
          key: 'sku'
        }, {
          title: 'SKU',
          key: 'sku'
        }, {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1006068') + '(g)',
          key: 'productWeight'
        }
      ],
      validErrorData: null,
      tabPane: '1',
      tabPaneText: [
        {
          label(h) {
            return h('div', alias47916751af154eb5b47cd5de9d34633d.t('key1006069'));
          },
          value: '1'
        }, {
          label(h) {
            return h('div', alias47916751af154eb5b47cd5de9d34633d.t('key1001286'));
          },
          value: '2'
        }
      ],
      model4: false,
      model5: false,
      cacheFormInline: null,
      formInline: {
        enableWeight: 'Y', // 是否需要称重 N 不需要 Y 需要*/
        weightUnit: 'g', //  g , kg
        enableVolume: 'Y', // 是否需要量尺寸 N 不需要 Y 需要
        autoDelivery: 'Y', // 是否自动执行发货 Y 是 N 否
        singleWeightRemark: 'Y', // 单品称重异常提醒 Y是 N否
        singleTranscend: '', // 单品称重误差阀值 单位g
        singleTranscendPercent: '', // 单品称重误差百分比
        multiWeightRemark: 'Y', // 多品称重异常提醒 Y是 N否
        multiTranscend: '', // 多品称重误差阀值 单位g
        multiTranscendPercent: '', // 多品称重误差百分比
        eachPiecePostageRemark: 'Y', // 逐单扫描运费异常提醒 Y是 N否
        postageTranscend: '' // 运费误差阀值
      },
      localFormInline: {
        enableWeight: 'N',
        enableVolume: 'N'
      },
      ruleInline: {},
      disabledGroup: '0',
      disabled1Group: '0',
      paramsWeight: 0,
      weightTypeList: [
        {
          label: alias47916751af154eb5b47cd5de9d34633d.t('key1003211'),
          value: 'g'
        }, {
          label: alias47916751af154eb5b47cd5de9d34633d.t('key1003212'),
          value: 'kg'
        }
      ],
      overbagModel: {
        status: false,
        overbagShippingName: '',
        packageCount: '',
        packageWeight: 0,
        overbagConfirm: true, // 默认勾选
        shippingMethodId: ''
      },
      packageModel: {
        shippingMethod: '',
        nation: '',
        postcode: '',
        code: '',
        waybillNo: '',
        weight: '',
        shippingMethodId: '',
        webstoreItemSite: 0
      },
      scanData: [],
      goodsData: [],
      scanColumn: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000965'),
          key: 'packageCode',
          align: 'center',
          render(h, params) {
            return h('div', [
              h('span', params.row.packageCode), h('Tooltip', {
                props: {
                  transfer: true,
                  maxWidth: 250,
                  content: params.row.error
                },
                style: {
                  marginLeft: '5px'
                }
              }, [
                h('Icon', {
                  props: {
                    type: 'md-information-circle',
                    size: 16
                  },
                  style: {
                    color: 'red',
                    display: (() => {
                      return params.row.error
                        ? 'inline-block'
                        : 'none';
                    })()
                  }
                })
              ])
            ]);
          }
        }, {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1002921'),
          align: 'center',
          key: 'waybillNo'
        }, {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001828'),
          align: 'center',
          key: 'country'
        }, {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001834'),
          align: 'center',
          key: 'postcode'
        }, {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1003194') + '(g)',
          align: 'center',
          key: 'weight',
          width: 90,
          render(h, params) {
            return h('div', {}, [h('span', {}, params.row.weight)]);
          }
        }, {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000283'),
          align: 'center',
          width: 180,
          render(h, params) {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias47916751af154eb5b47cd5de9d34633d.t('key1000092'),
                    color: '#FF0000',
                    show: true,
                    clickFn: () => {
                      v.deleteSingleGoods(params.row.packageCode);
                    }
                  },
                  {
                    text: alias47916751af154eb5b47cd5de9d34633d.t('key1005144'),
                    show: v.getPermission('packageInfo_scanMarkPackageDelivery'),
                    clickFn: () => {
                      v.tableSingleMarkGoods({
                        deliveryBatchNo: v.deliveryBatchNo,
                        flag: null,
                        height: params.row.height,
                        length: params.row.length,
                        packageId: params.row.packageId,
                        weight: params.row.weight,
                        width: params.row.width
                      }, params.row.packageCode);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      formItem: {
        height: '',
        width: '',
        length: '',
        weight: '',
        packageId: '',
        scan: ''
      },
      poptipModel: false,
      packageSettingModel: {
        settingSelect: [],
        weight: 'g',
        cacheWeight: 'g'
      },
      storeagePackageSetting: [],
      storeageNum: [1],
      deliveryBatchNo: '',
      shippingMethodPos: [],
      shippingGoodsMethodPos: [],
      clearTime: null,
      activeDeliver: null, // 缓存当前扫描数据
      isBatch: false,
      scanning_status: 0,
      scanning_time: '',
      supplierDeliveryData: [], // 供应商扫描标记发货的数据
      supplierPrint: false, // 供应商标记发货结袋是否打印箱唛
      supplierdeliveryModal: false,
      supplierPackageWeight: 0, // 供应商标记发货结袋时的总重量
      printInfo: {
        skuBarcode: null,
        createdTime: '',
        wmsPickupOrderNumberBarcode: null,
        pickupOrderNumber: null,
        packageQuantity: null
      },
      styles: {
        paddingTop: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '11pt',
        color: '#000',
        minWidth: '60pt'
      }
    };
  },
  computed: {
    goodsColumn() {
      let v = this;
      if (v.scanColumn) {
        let arr = JSON.parse(JSON.stringify(v.scanColumn));
        arr.splice(-1, 1);
        return arr;
      } else {
        return [];
      }
    },
  },
  created() {
    let v = this;
    v.getDeliverySetting();
    let scanSliceNumber = localStorage.getItem('scanSliceNumber');
    if (scanSliceNumber) {
      let data = JSON.parse(scanSliceNumber);
      v.isSliceIdentificationNumber = data.isSliceIdentificationNumber;
      v.sliceStartLength = data.sliceStartLength;
      v.sliceEndLength = data.sliceEndLength;
    }
    v.axios.get(api.get_packageInfo_getDeliveryBatchNo).then(response => {
      if (response.data.code === 0) {
        v.deliveryBatchNo = response.data.datas;
      }
    });
    // v.$store.commit('active', '88889-888892');
  },
  methods: {
    saveLocalData() {
      localStorage.setItem('scanSliceNumber', JSON.stringify({
        isSliceIdentificationNumber: this.isSliceIdentificationNumber,
        sliceStartLength: this.sliceStartLength,
        sliceEndLength: this.sliceEndLength
      }));
    },
    model4Visible(n) {
      if (n) {
        this.cacheFormInline = JSON.parse(JSON.stringify(this.formInline));
      } else {
        this.formInline = JSON.parse(JSON.stringify(this.cacheFormInline));
      }
    },
    paramData(flag) {
      let v = this;
      // flag 关乎包裹回收
      let params = {
        deliveryBatchNo: v.deliveryBatchNo,
        flag: null,
        height: v.formItem.height,
        length: v.formItem.length,
        packageId: v.formItem.packageId,
        weight: v.paramsWeight,
        width: v.formItem.width,
        unit: v.packageSettingModel.weight
      };
      if (flag) {
        params.flag = 1;
      }
      return params;
    },
    continueDeliverGoods() {
      let v = this;
      v.model5 = false;
      if (v.formInline.autoDelivery === 'Y') {
        v.singleMarkGoods(null, null, v.activeDeliver);
      } else {
        v.addScanOrSendGood('scanned', v.activeDeliver);
      }
    },
    validPackage(flag) {
      let v = this;
      let params = v.paramData(flag);
      params.warehouseId = v.getWarehouseId();
      v.axios.put(api.put_packageInfo_validateDeliveryPackage, params).then(res => {
        v.$nextTick(() => {
          v.formItem.weight = '';
          v.scanning_status = 0;
        });
        if (res.data.code === 0) {
          clearInterval(v.scanning_time);
          if (res.data.datas.validate) {
            // 校验成功
            if (v.formInline.autoDelivery === 'Y') {
              v.singleMarkGoods(null, null, {
                ...params,
                packageCode: v.packageModel.code,
                waybillNo: v.packageModel.waybillNo,
                country: v.packageModel.nation,
                postcode: v.packageModel.postcode,
                weight: v.paramsWeight,
                shippingMethodId: v.packageModel.shippingMethodId,
                shippingMethod: v.packageModel.shippingMethod
              });
            } else {
              v.addScanOrSendGood('scanned', {
                ...params,
                packageCode: v.packageModel.code,
                waybillNo: v.packageModel.waybillNo,
                country: v.packageModel.nation,
                postcode: v.packageModel.postcode,
                weight: v.paramsWeight,
                shippingMethodId: v.packageModel.shippingMethodId,
                shippingMethod: v.packageModel.shippingMethod
              });
            }
          } else {
            res.data.datas.productWeight = res.data.datas.productWeight
              ? res.data.datas.productWeight
              : 0;
            res.data.datas.materialWeight = res.data.datas.materialWeight
              ? res.data.datas.materialWeight
              : 0;
            v.validErrorData = res.data.datas;
            v.activeDeliver = {
              ...params,
              packageCode: v.packageModel.code,
              waybillNo: v.packageModel.waybillNo,
              country: v.packageModel.nation,
              postcode: v.packageModel.postcode,
              weight: v.paramsWeight,
              shippingMethodId: v.packageModel.shippingMethodId,
              shippingMethod: v.packageModel.shippingMethod
            };
            v.model5 = true;
            // 校验失败
          }
        }
      }).catch(() => {
      });
    },
    singleMarkGoods(flag, initParams, tableItem) {
      let v = this;
      //  单个标发货
      let params = initParams || v.paramData(flag);
      params.warehouseId = v.getWarehouseId();
      delete params.flag; // #IDY-4665
      v.axios.put(api.put_packageInfo_markPackageDelivery, params).then(res => {
        if (res.data.code === 0) {
          v.addScanOrSendGood('goods', tableItem);
          v.scanData.forEach((i, index) => {
            i.forEach((j, childIndex) => {
              if (j.packageCode === tableItem.packageCode) {
                if (v.scanData[index].length <= 1) {
                  v.scanData.splice(index, 1);
                  v.shippingMethodPos.splice(index, 1);
                } else {
                  v.scanData[index].splice(childIndex, 1);
                }
              }
            });
          });
          v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1006070'));
        }
      }).catch(() => {
      });
    },
    tableSingleMarkGoods(params, packageCode) {
      let v = this;
      params.warehouseId = v.getWarehouseId();
      delete params.flag; // #IDY-4665
      v.axios.put(api.put_packageInfo_markPackageDelivery, params).then(res => {
        if (res.data.code === 0) {
          v.scanData.forEach((i, index) => {
            i.forEach((j, childIndex) => {
              if (j.packageCode === packageCode) {
                v.addScanOrSendGood('goods', {
                  packageCode: j.packageCode,
                  waybillNo: j.waybillNo,
                  country: j.country,
                  postcode: j.postcode,
                  weight: j.weight,
                  shippingMethodId: j.shippingMethodId,
                  shippingMethod: j.shippingMethod
                });
                if (v.scanData[index].length <= 1) {
                  v.scanData.splice(index, 1);
                  v.shippingMethodPos.splice(index, 1);
                } else {
                  v.scanData[index].splice(childIndex, 1);
                }
              }
            });
          });
          v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1006070'));
        }
      }).catch(() => {
      });
    },
    deleteSingleGoods(packageCode) {
      let v = this;
      v.isDelModal(() => {
        v.scanData.forEach((i, index) => {
          i.forEach((j, childIndex) => {
            if (j.packageCode === packageCode) {
              if (v.scanData[index].length <= 1) {
                v.scanData.splice(index, 1);
                v.shippingMethodPos.splice(index, 1);
              } else {
                v.scanData[index].splice(childIndex, 1);
              }
            }
          });
        });
      });
    },
    deleteAllGoods() {
      let v = this;
      if (v.scanData.length > 0) {
        v.isDelModal(() => {
          v.scanData = [];
        });
      }
    },
    batchMarkGoods(obj) {
      let v = this;
      let params;
      if (obj) {
        params = obj;
      } else {
        params = {
          flag: null,
          packages: []
        };
        let arr = [];
        if (v.scanData.length === 0) return;
        v.scanData.forEach(i => {
          i.forEach(j => {
            arr.push(j);
          });
        });
        params.packages = arr;
      }
      delete params.flag; // # IDY-4665
      params.warehouseId = v.getWarehouseId();
      v.axios.put(api.put_packageInfo_scanBatchMarkPackageDelivery, params).then(res => {
        if (res.data.code === 0) {
          // 异常包裹保留
          let data = res.data.datas;
          let arr = [];
          v.scanData = [];
          v.shippingMethodPos = [];
          if (data.exceptions && data.exceptions.length > 0) {
            data.exceptions.forEach(i => {
              v.scanData.forEach(j => {
                j.forEach(k => {
                  if (k.packageId === i.packageId) {
                    arr.push({
                      ...k,
                      error: i.error,
                      errorCode: i.errorCode,
                      packageCode: i.packageCode
                    });
                  }
                });
              });
            });
            arr.forEach(i => {
              v.$nextTick(() => {
                v.addScanOrSendGood('scanned', i);
              });
            });
          }
          if (data.successPackages && data.successPackages.length > 0) {
            // 标发货成功的包裹
            params.packages.forEach(i => {
              data.successPackages.forEach(j => {
                if (i.packageCode === j.packageCode) {
                  j.weight = i.weight;
                }
              });
            });
            data.successPackages.forEach(i => {
              v.$nextTick(() => {
                v.addScanOrSendGood('goods', {
                  packageCode: i.packageCode,
                  waybillNo: i.trackingNumber,
                  country: i.buyerCountryCode,
                  postcode: i.buyerPostalCode,
                  weight: i.weight,
                  shippingMethodId: i.shippingMethodId,
                  shippingMethod: i.shippingMethodName
                });
              });
            });
            v.scanData.forEach((i, index) => {
              i.forEach((j, childIndex) => {
                data.successPackages.forEach(k => {
                  if (j.packageCode === k.packageCode) {
                    if (v.scanData[index].length <= 1) {
                      v.scanData.splice(index, 1);
                      v.shippingMethodPos.splice(index, 1);
                    } else {
                      v.scanData[index].splice(childIndex, 1);
                    }
                  }
                });
              });
            });
          }
        }
      }).catch(() => {
      });
    },
    addScanOrSendGood(type, params) {
      // 添加到已扫描或者已发货
      let v = this;
      if (type === 'scanned') {
        v.tabPane = '1';
        let tbj = {};
        if (params) {
          tbj = params;
        }
        if (v.shippingMethodPos.indexOf(tbj.shippingMethod) < 0) {
          v.shippingMethodPos.push(tbj.shippingMethod);
          v.scanData.push([tbj]);
        } else {
          let i = v.shippingMethodPos.indexOf(tbj.shippingMethod);
          let valid = false;
          v.scanData[i].forEach(i => {
            if (i.packageCode === tbj.packageCode) {
              valid = true;
            }
          });
          if (valid) {
            v.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1006071'));
            return;
          }
          v.scanData[i].push(tbj);
        }
      } else if (type === 'goods') {
        v.tabPane = '2';
        let tbj = {};
        if (params) {
          tbj = params;
        } else {
          tbj = {
            packageCode: v.packageModel.code,
            waybillNo: v.packageModel.waybillNo,
            country: v.packageModel.nation,
            postcode: v.packageModel.postcode,
            weight: v.paramsWeight,
            shippingMethodId: v.packageModel.shippingMethodId
          };
        }
        if (v.shippingGoodsMethodPos.indexOf(tbj.shippingMethod) < 0) {
          v.shippingGoodsMethodPos.push(tbj.shippingMethod);
          v.goodsData.push([tbj]);
        } else {
          let i = v.shippingGoodsMethodPos.indexOf(tbj.shippingMethod);
          v.goodsData[i].push(tbj);
        }
        v.supplierDeliveryData.push(tbj);
      }
      v.formItem.scan = '';
      v.formItem.weight = '';
      v.formItem.length = '';
      v.formItem.width = '';
      v.formItem.height = '';
      v.paramsWeight = 0;
      v.$refs['input1'].focus();
    },
    paramsHandel() {
      let v = this;
      let arr = [];
      v.disabledGroup === '0'
        ? (v.formInline.singleTranscendPercent = '')
        : (v.formInline.singleTranscend = '');
      v.disabled1Group === '0'
        ? (v.formInline.multiTranscendPercent = '')
        : (v.formInline.multiTranscend = '');
      for (let key in v.formInline) {
        arr.push({
          paramKey: key,
          paramValue: v.formInline[key]
        });
      }
      return arr;
    },
    saveDeliverySetting() {
      let v = this;
      let params = v.paramsHandel();
      v.axios.post(api.set_DeliverySetting, {
        paramBoList: params
      }).then(res => {
        if (res.data.code === 0) {
          v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000103'));
          for (let key in v.formItem) {
            v.formItem[key] = '';
          }
          v.model4 = false;
          v.getDeliverySetting();
        }
      }).catch(() => {
      });
    },
    getDeliverySetting() {
      let v = this;
      return new Promise(resolve => {
        v.axios.get(api.get_DeliverySetting).then(res => {
          if (res.data.code === 0) {
            res.data.datas.forEach(i => {
              for (let key in v.formInline) {
                if (i.paramKey === key) {
                  v.formInline[key] = i.paramValue;
                }
              }
            });
            v.formInline.singleTranscendPercent === ''
              ? (v.disabledGroup = '0')
              : (v.disabledGroup = '1');
            v.formInline.multiTranscendPercent === ''
              ? (v.disabled1Group = '0')
              : (v.disabled1Group = '1');
            v.localFormInline = Object.assign({}, v.formInline);
            if (v.formInline.enableWeight === 'Y' && v.formInline.enableVolume === 'Y') {
              v.storeageNum = [1, 2, 3, 4, 5];
            } else if (v.formInline.enableWeight === 'Y' && v.formInline.enableVolume === 'N') {
              v.storeageNum = [1, 2];
            } else if (v.formInline.enableWeight === 'N' && v.formInline.enableVolume === 'Y') {
              v.storeageNum = [1, 3, 4, 5];
            }
            v.packageSettingModel.cacheWeight = v.formInline.weightUnit;
            v.packageSettingModel.weight = v.formInline.weightUnit;
            resolve();
          }
        }).catch(() => {
        });
      });
    },
    cancelPoptip() {
      this.poptipModel = false;
    },
    savePoptip() {
      let v = this;
      localStorage.setItem('exSetting', v.packageSettingModel.settingSelect);
      localStorage.setItem('cacheWeight', v.packageSettingModel.cacheWeight);
      v.storeagePackageSetting = v.packageSettingModel.settingSelect;
      let exSetting = localStorage.getItem('exSetting');
      if (exSetting) {
        v.packageSettingModel.settingSelect = exSetting.split(',');
        v.storeagePackageSetting = exSetting.split(',');
        if (exSetting.indexOf(alias47916751af154eb5b47cd5de9d34633d.t('key1003194')) >= 0 && exSetting.indexOf(alias47916751af154eb5b47cd5de9d34633d.t('key1006072')) >= 0) {
          v.storeageNum = [1, 2, 3, 4, 5];
        } else if (exSetting.indexOf(alias47916751af154eb5b47cd5de9d34633d.t('key1003194')) >= 0 && exSetting.indexOf(alias47916751af154eb5b47cd5de9d34633d.t('key1006072')) < 0) {
          v.storeageNum = [1, 2];
        } else if (exSetting.indexOf(alias47916751af154eb5b47cd5de9d34633d.t('key1003194')) < 0 && exSetting.indexOf(alias47916751af154eb5b47cd5de9d34633d.t('key1006072')) >= 0) {
          v.storeageNum = [1, 3, 4, 5];
        }
      } else {
        v.storeageNum = [1];
      }
      if (v.packageSettingModel.weight !== v.packageSettingModel.cacheWeight && v.formItem.weight) {
        if (v.packageSettingModel.cacheWeight === 'g') {
          v.formItem.weight = (v.formItem.weight * 1000).toFixed(2);
        } else {
          v.formItem.weight = (v.formItem.weight / 1000).toFixed(2);
        }
      }
      v.packageSettingModel.weight = v.packageSettingModel.cacheWeight;
      v.poptipModel = false;
    },
    goNextInput(num) {
      let v = this;
      const l = v.storeageNum.length;
      const cur = v.storeageNum.indexOf(num);
      let xi = v.packageSettingModel.weight === 'kg'
        ? 1000
        : 1; // 如果是kg 需乘1000
      if (v.formItem.scan === '') {
        v.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1006073'));
        return false;
      }
      if (num > 1) {
        this.paramsWeight = Number(this.formItem.weight * xi).toFixed(2);
        /* let reg = /^\d*(\.)?(\d{1,2})?$/;
         let value = this.$refs['input' + num].value;
         if (!reg.test(value)) {
         v.$Message.error('该选项只允许输入整数或两位小数');
         return false;
         } */
      }
      if (num === 1 && v.formItem.scan !== '') {


        v.formItem.weight = '';
        if (v.formInline.enableWeight === 'Y') {
          v.$refs['input2'].focus();
        }
        if (!v.getPermission('packageInfo_getPackageByCode')) {
          v.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1006074'));
          return;
        }
        let identificationNumber = v.formItem.scan;
        if (v.isSliceIdentificationNumber) {
          if (v.sliceStartLength) identificationNumber = identificationNumber.slice(v.sliceStartLength,);
          if (v.sliceEndLength) identificationNumber = identificationNumber.slice(0, identificationNumber.length - v.sliceEndLength);
        }
        v.axios.put(api.get_packageByCode, {
          'code': identificationNumber,
          'warehouseId': v.getWarehouseId()
        }).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            v.packageModel.code = data.packageCode;
            v.packageModel.postcode = data.buyerPostalCode;
            v.packageModel.nation = data.buyerCountryCode;
            v.packageModel.shippingMethod = data.carrierName + ' > ' + data.shippingMethodName;
            v.packageModel.waybillNo = data.trackingNumber;
            v.packageModel.shippingMethodId = data.shippingMethodId;
            v.packageModel.webstoreItemSite = data.webstoreItemSite;
            v.formItem.packageId = data.packageId;
            v.scanning_status = 1;
            if (v.formInline.enableWeight === 'Y') {
              // v.$refs['input2'].focus();
            } else if (v.formInline.enableVolume === 'Y') {
              v.$refs['input3'].focus();
            } else {
              v.validPackage(); // 检查是否有异常
            }
          } else {
            v.$refs['input1'].focus();
            v.scanning_status = 0;
          }
        });
      } else if (num === 2) {
        v.paramsWeight = Number(v.formItem.weight * xi).toFixed(2);
        v.formItem.length = '';
        v.formItem.width = '';
        v.formItem.height = '';
        if (v.formInline.enableVolume === 'Y') {
          v.$refs['input2'].focus();
        } else {
          v.scanning_time = setInterval(() => {
            v.scanning_status === 1
              ? v.validPackage()
              : '';
          }, 1000);
        }
      } else if (num === 3) {
        v.$refs['input4'].focus();
      } else if (num === 4) {
        v.$refs['input5'].focus();
      } else if (num === 5) {
        v.validPackage(); // 检查是否有异常
      }
      /*
       if (cur === l - 1 && l !== 1) {
       v.validPackage(); // 检查是否有异常
       // v.setMarkPackage();
       } else if (cur !== l - 1 && num !== 1) {
       v.$refs['input' + (num + 1)].focus();
       } */
    },
    toFixedTwo(e) {
      this.formItem.weight = Number(this.formItem.weight).toFixed(2);
    },
    keepOut() {
      if (this.isBatch) {
        this.batchMarkGoods(this.activeDeliver);
      } else {
        this.tableSingleMarkGoods(this.activeDeliver, this.activeDeliver.packageCode);
      }
    },
    goCancel() {
      this.$router.push('cancelPackage');
    },
    setMarkPackage(flag) {
      // 标记包裹出库
      let v = this;
      let obj = v.deepCopy(v.formItem);
      delete obj.scan;
      obj.deliveryBatchNo = v.deliveryBatchNo;
      obj.weight = v.paramsWeight;
      /* if (flag) {
       obj.flag = 1;
       } */
      delete obj.flag;
      v.axios.put(api.put_packageInfo_markPackageDelivery, JSON.stringify(obj)).then(response => {
        if (response.data.code === 0) {
          let tbj = {
            packageCode: v.packageModel.code,
            waybillNo: v.packageModel.waybillNo,
            country: v.packageModel.nation,
            postcode: v.packageModel.postcode,
            weight: v.paramsWeight,
            shippingMethodId: v.packageModel.shippingMethodId
          };
          if (v.shippingMethodPos.indexOf(v.packageModel.shippingMethod) < 0) {
            v.shippingMethodPos.push(v.packageModel.shippingMethod);
            v.scanData.push([tbj]);
          } else {
            let i = v.shippingMethodPos.indexOf(v.packageModel.shippingMethod);
            v.scanData[i].push(tbj);
          }
          v.formItem.scan = '';
          v.formItem.weight = '';
          v.formItem.length = '';
          v.formItem.width = '';
          v.formItem.height = '';
          v.paramsWeight = 0;
          v.$refs['input1'].focus();
        }
      });
    },
    setPackageOver(item, index) {
      let v = this;
      v.overbagModel.status = true;
      v.overbagModel.overbagShippingName = item[0].shippingMethod;
      v.overbagModel.packageCount = v.goodsData[index].length;
      v.overbagModel.packageWeight = 0;
      v.goodsData[index].forEach((n, i) => {
        v.overbagModel.packageWeight = Number(n.weight) + Number(v.overbagModel.packageWeight);
      });
      v.overbagModel.shippingMethodId = item[0].shippingMethodId;
    },
    // 供应商标记发货的结袋按钮
    supplierBaggingBtn() {
      this.supplierdeliveryModal = true;
      this.supplierDeliveryData.map((item) => {
        this.supplierPackageWeight += Number(item.weight);
      });
    },
    // 供应商标记发货结袋弹窗的确认按钮
    supplierQueryBtn() {
      let v = this;
      let query = {
        packageCodes: [],
        print: v.supplierPrint
      };
      if (v.supplierDeliveryData.length > 0) {
        v.supplierDeliveryData.map((item) => {
          query.packageCodes.push(item.packageCode);
        });
      }
      v.axios.post(api.post_wmsPickupOrder_packageBagging, query).then((response) => {
        if (response.data.code === 0) {
          if (response.data.datas.wmsPrintPickupOrderResultBo) {
            v.printInfo = response.data.datas.wmsPrintPickupOrderResultBo;
            v.printInfo.skuBarcode = v.entityToString(v.printInfo.wmsPickupOrderNumberBarcode);
          }
          if (v.printInfo && v.supplierPrint) {
            v.$nextTick(() => {
              v.printBtn();
            });
          }
          if (!v.supplierPrint) {
            v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000412'));
          }
          v.supplierdeliveryModal = false;
          v.supplierDeliveryData = [];
        }
      });
    },
    overBag() {
      let v = this;
      if (v.overbagModel.overbagConfirm) {
        let newTab = window.open('about:blank');
        v.axios.get(api.set_packageOverBag + '?deliveryBatchNo=' + v.deliveryBatchNo + '&&shippingMethodId=' + v.overbagModel.shippingMethodId).then(response => {
          if (response.data.code === 0) {
            let erpCommon = v.$store.state.erpConfig;
            newTab.location.href = erpCommon.filenodeViewTargetUrl + response.data.datas;
            setTimeout(function () {
              newTab.close();
            }, 2000);
          } else {
            newTab.close();
          }
        });
      }
    },
    gotoExwarehouse() {
      this.$router.push({
        name: 'wms/packingManage',
        params: {
          tabsName: 'exWarehouse'
        }
      });
    },
    // 打印
    printBtn() {
      let v = this;
      v.publicPrintBtn('yms-supplier-service', v.htmlText());
    },
    htmlText(callback) {
      let v = this;
      let html = '';
      let dom = v.deepCopy(v.$refs.container);
      let tagTempBoxs = v.$refs.tagTemp;
      tagTempBoxs.innerHTML = dom.parentNode.innerHTML;
      let container = tagTempBoxs.childNodes[0];
      if (v.printInfo) {
        container.childNodes[0].innerText = alias47916751af154eb5b47cd5de9d34633d.t('key1000920') + v.$uDate.getDataToLocalTime(v.printInfo.createdTime, 'fulltime');
        container.childNodes[1].innerHTML = v.printInfo.skuBarcode;
        container.childNodes[2].innerText = v.printInfo.pickupOrderNumber;
        container.childNodes[3].innerText = alias47916751af154eb5b47cd5de9d34633d.t('key1000185') + v.printInfo.packageQuantity;
        callback && callback();
        html += tagTempBoxs.innerHTML;
        return html;
      }
    }
  }
};
</script>
<style>
.ivu-tooltip-inner {
  width: 250px;
  white-space: normal;
}
</style>
<style lang="less" scoped>
.printCaseMarkBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: #333;

  .print_item {
    margin-bottom: 10px;
  }

  .bar_code {
    padding: 5px 10px;
  }
}

.w-40 {
  width: 40px;
}

.inline-block {
  display: inline-block
}

.autoRight {
  overflow-y: auto;
  overflow-x: hidden;
  height: 850px;
}

.settingCursor {
  cursor: pointer;
}

.checkboxStyle {
  display: block;
  padding-bottom: 10px;
}

.packageCode {
  justify-content: center;
  font-size: 24px;
  color: #0054a6;
}

.packageNationFlag {
  justify-content: center;
}

.shippingCode {
  justify-content: center;
  font-size: 16px;
  margin-top: 10px;
  color: #3cb034;
}

.shippingCode em {
  font-style: normal;
  margin-right: 10px;
  color: #333;
}

.packageItem {
  font-size: 24px;
}

.packageSpecialItem {
  font-size: 36px;
  color: #113f6d;
  font-weight: bold;
}

.packageSetting {
  background-color: #dbebd4;
  height: 190px;
  border-bottom: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.packageSettingBody {
  border-top: 1px solid #e1e1e1;
}

.packageSettingFont {
  line-height: 34px;
  margin-left: 5px;
}

.scanPageItem {
  margin-bottom: 10px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
}

.scanPageItemHeader {
  border-bottom: 1px solid #e9eaec;
  padding: 13px 16px;
  line-height: 1;
}

.scanPageItemHeaderContent {
  width: 100%;
  height: 24px;
  line-height: 20px;
  font-size: 14px;
  color: #1c2438;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spicLeft {
  margin-left: 15px;
}

.scanPageItemBody {
  padding: 10px;
}

.overbagShippingName {
  font-size: 24px;
  color: #0054a6;
}

.overbagParams {
  font-size: 20px;
}

.overbagParams span {
  color: #ff3300;
}

.gotoExwarehouse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}

.normalTop {
  margin-top: 10px !important;
}

.scanEx {
  margin-top: -15px;
  line-height: 32px;
}

.modelTit {
  line-height: 36px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.inline-block {
  display: inline-block;
}

.mr10 {
  margin-right: 10px;
}

.valid-bottom {
  font-size: 14px;
  padding: 20px;
}

.text-right {
  text-align: right;
}

.valid-tit {
  text-align: left;
  display: inline-block;
  width: 150px;
  line-height: 30px;
}

.scanFooter {
  position: fixed;
  bottom: 0;
  width: 42.7%;
  height: 50px;
  background-color: #6d7380;
}

.scanFooterBox {
  height: 100%;
  padding-top: 7px;
  text-align: center;
}

.ml10 {
  margin-left: 10px;
}

.mr10 {
  margin-right: 10px;
}
</style>
